html,
body,
.app-root {
  height: 100%;
  width: 100%;
  min-height: 100%;
}

.app {
  min-height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: black;
  background-image: linear-gradient(to left top,
    #000000, #151012, #211a1f, #2d242d,
    #2d242d, #2d242d, #2d242d, #2d242d, #2d242d, #2d242d, #2d242d,
    #2d242d, #2d242d, #2d242d, #2d242d, #2d242d, #2d242d, #2d242d,
    #302731, #322934, #352c38, #372f3c, #433f52, #4c5771, #576b86,
    #617f9a, #6c94ae, #78a9c1, #8ec9dd, #a6eaf7
  );
}

.app-wrapper {
  margin: 0 auto;
  max-width: 1400px;
  min-width: 300px;
  padding-bottom: 0;
  width: 100%;
}

.App-logo {
  height: 40vmin;
}
